<template>
  <div v-if="!pdfAPIReady || !dd">Loading Please wait...
    <div class="w-100 text-center">
      <h3 v-if="pdfAPIReady">
        <!-- PDF PreviewX: pdfReady: {{ pdfAPIReady }} pdfReady: {{ pdfReady }} pdf: {{ pdf }} -->
      </h3>
      <div id="pdf-view" style="height: 50vh;" />
    </div>
  </div>
  <div v-else>
    <div
      v-if="pdfAPIReady"
      class="w-100 text-center"
    >
      <b-row>
        <b-col cols="12" md="5">
          <b-button v-if="!isCalc" block variant="primary" class="mt-2"
            @click="calc()"
          >
            Unterhalt berechnen
          </b-button>

          <b-button v-if="isCalc" variant="primary" disabled="true" class="mt-2">
            <b-spinner small />
            Berechnung läuft...
          </b-button>
        </b-col>

        <b-col cols="12" md="3">
          <b-form-group class="text-left"
            label="Berechnung ab"
            label-for="unterhaltAb"
          >
            <flat-pickr
              v-model="dd.CalcFrom"
              :config="config"
              class="form-control"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4">
          <b-form-group
            label="Toleranzwert Phasen-Aggregation (CHF)"
            label-for="toleranzAggregation"
          >
          <vue-slider class="mt-1"
              v-model="dd.AggrWertPhase"
              :min="0"
              :max="200"
              :tooltip="'always'"
              :tooltip-placement="dd.AggrWertPhase < 47 ? 'right' : 'left'"
              />
          </b-form-group>
        </b-col>
      </b-row>

      <b-button
        v-if="isAdmin"
        class="mb-1"
        block
        variant="primary"
        @click="downloadCalc()"
      >
        Herunterladen
      </b-button>
      <!-- <b-button
          class="mb-1"
          block
          variant="primary"
          @click="show()"
        >
          Anzeigen
        </b-button> -->
      <div id="pdf-view" style="height: 75vh;" />
    </div>
    <b-form/>
  </div>
</template>

<script>
import {
  BButton, BSpinner, BRow, BCol, BForm, BFormGroup,
  // BForm,
} from 'bootstrap-vue'
import VueSlider from 'vue-slider-component'
import flatPickr from 'vue-flatpickr-component'
import { German } from 'flatpickr/dist/l10n/de'
import { French } from 'flatpickr/dist/l10n/fr'
import dos from '@/service/dossier'
import db from '@/service/unterhalt'

export default {
  components: {
    BButton,
    BSpinner,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    flatPickr,
    VueSlider,
  },
  data() {
    const ADOBE_KEY = (window.location.href.toString().includes('localhost') ? 'b128238126aa454b8bb908b2a06cd02f' : 'a86fda26efa441559b28d422cbadb90b')
    const viewerConfig = {
      embedMode: 'LIGHT_BOX',
    }
    return {
      data: null,
      dd: null,
      pdf: null,
      pdfReady: false,
      ADOBE_KEY,
      viewerConfig,
      isCalc: false,
      pdfAPIReady: this.$root.pdfAPIReady,
      isAdmin: this.$g.user.Rolle === 'admin',
      excel: null,
      config: {
        wrap: true, // set wrap to true only when using 'input-group'
        dateFormat: 'Y-m-d', // Internes Format zu Speicherung...
        altInput: true,
        altFormat: 'd.m.Y', // Anzeigeformat...
        allowInput: true,
        locale: this.$g.user.Sprache === 'fr' ? French : German,
      },
    }
  },
  async created() {
    const rd = await dos.getDossier(this.$root.currDosID)
    if (rd.data.length > 0) this.dd = rd.data[0]

    const r = await db.getPdf(this.$root.currDosID)
    if (r !== null && r.data.byteLength > 0) {
      this.data = r.data
      this.pdf = new Blob([r.data], { type: 'application/pdf' })
      await this.previewPDF()
    }
  },
  methods: {
    async calc() {
      this.pdfReady = false
      this.isCalc = true
      // setTimeout(() => { console.log('World!') }, 5000)
      await db.getCalc(this.$root.currDosID, this.dd.CalcFrom, this.dd.AggrWertPhase)
      const r = await db.getPdf(this.$root.currDosID)
      if (r !== null) {
        this.data = r.data
        this.pdf = new Blob([r.data], { type: 'application/pdf' })
        await this.previewPDF()
      }
      this.isCalc = false
      this.$root.showToast(this.$g.browserLocale === 'fr' ? 'Calcul' : 'Berechnung', this.$g.browserLocale === 'fr' ? 'Le calcul a été executé avec succès!' : 'Die Berechnung wurde erfolgreich ausgeführt!')
    },
    async show() {
      await this.previewPDF()
    },
    async downloadCalc() {
      const r = await db.getExcel(this.$root.currDosID)
      this.excel = new Blob([r.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      const fileURL = window.URL.createObjectURL(this.excel)
      const fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.setAttribute('download', 'unterhalt.xlsx')
      document.body.appendChild(fileLink)
      fileLink.click()
    },
    async previewPDF() {
      if (this.pdfAPIReady) {
        this.pdfReady = false
        const av = new window.AdobeDC.View({ clientId: this.ADOBE_KEY, divId: 'pdf-view', locale: (this.$g.user.Sprache === 'fr' ? 'fr-FR' : 'de-DE') })
        const pvp = av.previewFile({ content: { promise: Promise.resolve(this.pdf.arrayBuffer()) }, metaData: { fileName: 'Unterhalt' }, configuration: this.viewerConfig }) // eslint-disable-line
        this.pdfReady = true
      }
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-slider.scss';
</style>
